<template>
  <div>
    <img src="/404.svg" alt="404">
    <h1>{{ $t('Page not found') }}</h1>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
}
</script>

<style scoped>
img {
  width: 123%;
  height: auto;
  object-fit: contain;
  object-position: top left;
  margin-top: 2rem;
}
h1 {
  font-size: clamp(1.5rem, 3vw, 4rem);
  text-align: center;
  text-transform: uppercase;
  margin: clamp(-800px, -0.4219 * 100vw + 10px, -125px) 0 2.5em;
}
</style>
